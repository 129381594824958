<template>
  <div class="page-container">
    <div class="page-header">
      <top-head :systemName="systemName" :colorOne="colorOne" :logo="imgBase + logo"></top-head>
    </div>
    <div class="app_center scroll_bar" :style="{ height: windowHeight + 'px' }">
      <div class="left-menu" :class="{ menuHide: !isMenuVisible }">
        <side-bar v-if="menu.length" :menu="menu" :colorOne="colorOne"></side-bar>
      </div>
      <div class="right_content">
        <div class="nav-bar">
          <div class="bar-left">
            <img
              @click="toggleLeftMenuShow"
              class="collapse"
              v-show="!isMenuVisible"
              src="../assets/image/unfold.png"
              alt=""
            />
            <img
              @click="toggleLeftMenuShow"
              class="collapse"
              v-show="isMenuVisible"
              src="../assets/image/fold.png"
              alt=""
            />
            <span class="current-nav">{{ pageTitle }}</span>
          </div>
          <div class="bar-right">
            <hm-button type="primary" class="right_item fr" @click="history_back"
              ><i class="iconfont icon-fanhui"></i>返回</hm-button
            >
          </div>
        </div>
        <div class="main-container scroll_bar">
          <div class="page_content" v-if="menu.length">
            <router-view></router-view>
          </div>
          <bottom-foot></bottom-foot>
        </div>
      </div>
    </div>
    <div class="service_chat_com" v-if="show_service">
      <div class="switch_wrap" v-show="!showChatFrame">
        <img
          src="../assets/image/service/service_1.png"
          alt=""
          class="chat_icon"
          @click="show_chat_frame"
        />
        <span class="new_icon" v-show="newMsg"></span>
      </div>
      <chat-frame
        ref="chatFrame"
        :visible.sync="showChatFrame"
        :emojiList="emojiList"
        :positionMap="positionMap"
        :msgTypeMap="msgTypeMap"
        :msgList="msgList"
        :loadingMsg="loadingMsg"
        :noMoreMsg="noMoreMsg"
        :customerList="customerList"
        :msgTemplate="msgTemplate"
        :userId="userId"
        :userInfo="userInfo"
        :mainColor="colorOne"
        @send="send_msg"
        @moreMessage="moreMessage"
        @toggleChatPerson="toggle_chat_person"
        @msgFile="get_msg_file"
        @closeChat="close_chat"
      >
      </chat-frame>
    </div>
    <div class="weixin-tip" v-if="isWeixin">
      <div class="tip">请使用浏览器打开</div>
    </div>
    <el-dialog
      :visible.sync="isUpdateVisible"
      title="公告"
      width="1000px"
      :close-on-click-modal="false"
    >
      <div style="padding: 20px" v-html="updateHtml"></div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui'
import ChatFrame from '@/components/service/ChatFrame.vue';
import SideBar from '../components/SideBar.vue';
import Head from '../components/Head.vue';
import Foot from '../components/Foot.vue';
import HmButton from '../components/Button.vue';
import appMixin from '../mixins/appMixin';
import loginMixin from '../mixins/loginMixin';
import { getUrlParam, isWeixin } from '@/utils/common';
import serviceMixin from '@/mixins/service/serviceMixin';
import serviceCommonMixin from '@/mixins/service/serviceCommonMixin';
export default {
  mixins: [appMixin, serviceCommonMixin, serviceMixin, loginMixin],
  data() {
    return {
      topHeight: 60,
      bottomHeight: 60,
      sideWidth: 230,
      menu: [],
      isMenuVisible: true,
      isWeixin: false,
      isUpdateVisible: false,
      updateHtml: '',
    };
  },
  created() {
    this.isWeixin = isWeixin();
    this.windowHeight = document.documentElement.clientHeight;
    this.initLog();
    this.initData();
    this.initSystemData();
    let auth_code = getUrlParam('auth_code');
    let redirectType = getUrlParam('redirectType');
    if (auth_code && redirectType) {
      switch (Number(redirectType)) {
        case 1:
          // 小程序授权
          this.auth_notify(auth_code);
          break;
      }
    }
  },
  computed: {
    windowHeight: {
      get() {
        return this.$store.state.windowHeight;
      },
      set() {}
    },
    colorOne: {
      get() {
        return this.$store.state.colorOne;
      },
      set() {}
    },
    systemName: {
      get() {
        return this.$store.state.systemName;
      },
      set() {}
    },
    logo: {
      get() {
        return this.$store.state.logo;
      },
      set() {}
    },
    pageTitle: {
      get() {
        return this.$store.state.pageTitle;
      },
      set() {}
    }
  },
  components: {
    'side-bar': SideBar,
    'top-head': Head,
    'bottom-foot': Foot,
    'chat-frame': ChatFrame,
    'hm-button': HmButton,
    'el-dialog': Dialog,
  },
  methods: {
    initLog() {
      this.rqUpdateLog().then((log) => {
        if (log && log.notice && !log.isRead) {
          this.updateHtml = log.notice.content;
          this.isUpdateVisible = true;
        }
      });
    },
    history_back() {
      this.$router.back();
    },
    toggleLeftMenuShow() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    async initData() {
      this.showLoading();
      try {
        // 菜单
        let indexData = await this.request_menu();
        this.hideLoading();
        let menu = indexData.menu;
        console.log(menu);
        this.menu = menu;
        this.systemName = indexData.systemName;
      } catch (err) {
        this.hideLoading();
      }
    },
    initSystemData() {
      this.request_system_data()
        .then((res) => {
          if (res.themeColor) {
            this.$store.dispatch('color_one', res.themeColor);
          }
          this.$store.dispatch('system_name', res.systemName);
          this.$store.dispatch('logo', res.logo);
        })
        .catch((err) => {});
    },
    request_system_data() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/setup/common/pageSetUp/getPageSetUp'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || {});
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    async auth_notify(auth_code) {
      try {
        await this.request_notify(auth_code);
        window.location.href = './index.html#/generalSetting';
      } catch (err) {
        this.$message({
          message: err.message,
          type: 'warning'
        });
        setTimeout(() => {
          window.location.href = './index.html';
        }, 800);
      }
    },
    request_notify(auth_code) {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/setup/xcx/auth/authNotify',
          method: 'post',
          data: {
            auth_code: auth_code
          }
        })
          .then((res) => {
            if (res.data.success) {
              resolve();
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    },
    rqUpdateLog() {
      return new Promise((resolve, reject) => {
        this.$http({
          url: '/business/system/notice/getNotice',
          method: 'post'
        })
          .then((res) => {
            if (res.data.success) {
              resolve(res.data.data || null);
            } else {
              reject({
                message: res.data.desc
              });
            }
          })
          .catch((res) => {
            reject({
              message: '请求发送失败'
            });
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$contentWidth: 1150px;
.weixin-tip {
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  .tip {
    color: #fff;
    font-size: 24px;
    text-align: center;
    line-height: 200px;
    letter-spacing: 24px;
  }
}
.page-container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.page-header {
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0;
  top: 0;
}
.app_center {
  padding-top: 60px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
}
.left-menu {
  flex-basis: 230px;
  flex-shrink: 0;
  overflow: hidden;
  background-color: #fff;
  transition: flex-basis 0.8s;
  -webkit-transition: flex-basis 0.8s;
  -o-transition: flex-basis 0.8s;
  -moz-transition: flex-basis 0.8s;
  -ms-transition: flex-basis 0.8s;
}
.menuHide {
  flex-basis: 0px;
  flex-grow: 0;
  transition: flex-basis 0.8s;
  -webkit-transition: flex-basis 0.8s;
  -o-transition: flex-basis 0.8s;
  -moz-transition: flex-basis 0.8s;
  -ms-transition: flex-basis 0.8s;
}
.right_content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: $contentWidth;
  .main-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .nav-bar {
    width: 100%;
    flex-basis: 50px;
    box-sizing: border-box;
    flex-shrink: 0;
    background-color: #fff;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
    display: flex;
    padding: 0 10px;
    .bar-left {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      .collapse {
        height: 30px;
        width: auto;
        cursor: pointer;
      }
      .current-nav {
        margin-left: 10px;
      }
    }
    .bar-right {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
.page_content {
  width: $contentWidth;
  margin: 0 auto;
  min-height: calc(100% - 110px);
}
.service_chat_com {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 999;
  .switch_wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    .chat_icon {
      width: 60px;
      height: auto;
      cursor: pointer;
    }
    .new_icon {
      position: absolute;
      right: -8px;
      top: -8px;
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #ff4d51;
      animation: blinkIn 1s linear infinite alternate;
      @keyframes blinkIn {
        0% {
          opacity: 0;
        }
        25% {
          opacity: 0.2;
        }
        50% {
          opacity: 0.5;
        }
        75% {
          opacity: 0.7;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
</style>
