export const customMenuMap = {
  ypOrder: 1,
  ypProduct: 2,
  ypTimeManage: 3,
  indexDecoration: 4,
  materialManage: 5,
  photoProduct: 6,
  photoOrder: 7,
  frameAlbumProduct: 8,
  userList: 9,
  rechargeActivity: 10,
  vipList: 11,
  vipLevelManage: 12,
  generalProduct: 13,
  xcxAuthorization: 14,
  paySetting: 15,
  accountEdit: 16,
  discountList: 17,
  rechargeLog: 18,
  storeList: 19,
  roleManage: 20,
  staffManage: 21,
  loginLog: 22,
  operationLog: 23,
  orderWithdraw: 24,
  withdrawLog: 25,
  withdrawSetting: 26,
  offlineGeneralProduct: 27,
  offlineYpProduct: 28,
  offlineYpOrder: 29,
  offlineGeneralOrder: 30,
  groupBuyManage: 31,
  yxtSubmitOrder: 32,
  yxtHome: 33,
  yxtOrderManage: 34,
  businessStyleSetting: 35,
  serviceManage: 36,
  autoReplyManage: 37,
  chatLog: 38,
  index: 39,
  storeResource: 40,
  goodsGroupList: 41,
  videoList: 42,
  distributionSetting: 43,
  distributionOrder: 44,
  distributionMemberList: 45,
  distributionCheckList: 46,
  withdrawLog2: 47,
  orderWithdraw2: 48,
  distributionProduct: 49,
  xiuTuHistory: 50,
  xiuTuSpreadList: 51,
  xiuTuSpreadStatistic: 52,
  xiuTuRecharge: Symbol(),
  TaskList: Symbol(),
  CreateAiTask: Symbol(),
  AiTemplate: Symbol(),
  RechargeList: Symbol(),
  CreativeClassify: Symbol(),
  CreativeUserList: Symbol(),
  SingleAi: Symbol(),
  CreativeTaskList: Symbol(),
  CreativeRechargeList: Symbol(),
  MultiAi: Symbol(),
  CreativeSpreadList: Symbol(),
  CreativeSpreadStatistic: Symbol(),
  CreativeSpreadStatistic: Symbol(),
  CreativeClassType: Symbol(),
  BdImage: Symbol(),
};
export const menuRouterMap = [
  {
    url: 'business/index',
    router: '/statistics', //首页
    id: customMenuMap.index
  },
  {
    url: 'business/order/manage/service',
    router: '/yporder', //服务订单
    id: customMenuMap.ypOrder
  },
  {
    url: 'business/product/services/service',
    router: '/goodmanage', //服务产品
    id: customMenuMap.ypProduct
  },
  {
    url: 'business/product/services/serviceTime',
    router: '/timemanage', //时间管理
    id: customMenuMap.ypTimeManage
  },
  {
    url: 'business/page/decorate/module',
    router: '/customlist', //模块编辑首页
    id: customMenuMap.indexDecoration
  },
  {
    url: 'business/page/decorate/resources',
    router: '/customres', //素材管理
    id: customMenuMap.materialManage
  },
  {
    url: 'business/product/diy/print',
    router: '/photoProduct', //冲印产品
    id: customMenuMap.photoProduct
  },
  {
    url: 'business/order/manage/product',
    router: '/photoOrder', //冲印订单
    id: customMenuMap.photoOrder
  },
  {
    url: 'business/product/diy/photoFrame',
    router: '/frameAlbum', //相框相册
    id: customMenuMap.frameAlbumProduct
  },
  {
    url: 'business/user/manager/list',
    router: '/vipList', //用户列表
    id: customMenuMap.userList
  },
  {
    url: 'business/promotion/recharge/rechargeActivity',
    router: '/rechargeActivity', //充值送
    id: customMenuMap.rechargeActivity
  },
  {
    url: 'business/user/vip/vipList',
    router: '/vipList2', //会员列表
    id: customMenuMap.vipList
  },
  {
    url: 'business/user/vip/vipManager',
    router: '/levelManage', //等级管理
    id: customMenuMap.vipLevelManage
  },
  {
    url: 'business/product/ec/products',
    router: '/generalProduct', //普通商品
    id: customMenuMap.generalProduct
  },
  {
    url: 'business/setup/xcx/auth',
    router: '/generalSetting', //小程序
    id: customMenuMap.xcxAuthorization
  },
  {
    url: 'business/setup/pay/manage',
    router: '/paySet', //小程序支付设置
    id: customMenuMap.paySetting
  },
  {
    url: 'business/setup/common/admin',
    router: '/basicSetting', //基本设置
    id: customMenuMap.accountEdit
  },
  {
    url: 'business/promotion/coupon/couponRule',
    router: '/discountList', //优惠券列表
    id: customMenuMap.discountList
  },
  {
    url: 'business/user/manager/rechargeLog',
    router: '/rechargeRecord', //充值记录
    id: customMenuMap.rechargeLog
  },
  {
    url: 'business/setup/store/manage',
    router: '/storeList', //門店列表
    id: customMenuMap.storeList
  },
  {
    url: 'business/setup/admin/role',
    router: '/roleList', //角色列表
    id: customMenuMap.roleManage
  },
  {
    url: 'business/setup/admin/manage',
    router: '/staffList', //员工列表
    id: customMenuMap.staffManage
  },
  {
    url: 'business/setup/admin/loginLog',
    router: '/loginHistory', //登录日志
    id: customMenuMap.loginLog
  },
  {
    url: 'business/setup/admin/log',
    router: '/operateLog', //操作日志
    id: customMenuMap.operationLog
  },
  {
    url: 'business/withdraw/manager/list',
    router: '/orderWithdraw', //订单提现
    id: customMenuMap.orderWithdraw
  },
  {
    url: 'business/withdraw/manager/log',
    router: '/withdrawLog', //提现记录
    id: customMenuMap.withdrawLog
  },
  {
    url: 'business/withdraw/manager/setup',
    router: '/withdrawSetting', //提现设置
    id: customMenuMap.withdrawSetting
  },
  {
    url: 'business/product/offline/products',
    router: '/offlineGeneral', //到店商品列表
    id: customMenuMap.offlineGeneralProduct
  },
  {
    url: 'business/product/offline/service',
    router: '/offlineYp', //到店拍摄列表
    id: customMenuMap.offlineYpProduct
  },
  {
    url: 'business/order/offline/service',
    router: '/offlineYpOrder', //到店拍摄订单
    id: customMenuMap.offlineYpOrder
  },
  {
    url: 'business/order/offline/products',
    router: '/offlineGeneralOrder', //到店普通商品订单
    id: customMenuMap.offlineGeneralOrder
  },
  {
    url: 'business/promotion/groupBuy/groupBuyRule',
    router: '/groupBooking', //拼团首页
    id: customMenuMap.groupBuyManage
  },
  {
    url: 'business/retouching/resource/list',
    router: '/yxtUpload', //云修图上传
    id: customMenuMap.yxtSubmitOrder
  },
  {
    url: 'business/retouching/user/center',
    router: '/yxtHome', //云修图个人中心
    id: customMenuMap.yxtHome
  },
  {
    url: 'business/retouching/order/list',
    router: '/yxtOrderList', //云修图订单
    id: customMenuMap.yxtOrderManage
  },
  {
    url: 'business/setup/common/pageSetUp',
    router: '/customSetting', //页面设置
    id: customMenuMap.businessStyleSetting
  },
  {
    url: 'business/im/manage/custservice',
    router: '/serviceList', //客服管理
    id: customMenuMap.serviceManage
  },
  {
    url: 'business/im/manage/template',
    router: '/autoReply', //客服自动回复
    id: customMenuMap.autoReplyManage
  },
  {
    url: 'business/im/manage/session',
    router: '/chatLog', //会话记录
    id: customMenuMap.chatLog
  },
  {
    url: 'business/page/decorate/store',
    router: '/storeResource', //会话记录
    id: customMenuMap.storeResource
  },
  {
    url: 'business/product/manage/group',
    router: '/goodsGroupList', //会话记录
    id: customMenuMap.goodsGroupList
  },
  {
    url: 'business/promotion/video/manage',
    router: '/videoList', //会话记录
    id: customMenuMap.videoList
  },
  {
    url: 'business/promoter/manage/setup',
    router: '/distributionSetting', //分销设置
    id: customMenuMap.distributionSetting
  },
  {
    url: 'business/promoter/manage/order',
    router: '/distributionOrder', //推广业绩
    id: customMenuMap.distributionOrder
  },
  {
    url: 'business/promoter/manage/auth',
    router: '/distributionCheckList', //审核列表
    id: customMenuMap.distributionCheckList
  },
  {
    url: 'business/promoter/manage/list',
    router: '/distributionMemberList', //推广员列表
    id: customMenuMap.distributionMemberList
  },
  {
    url: 'business/withdraw/extract/list',
    router: '/orderWithdraw2',
    id: customMenuMap.orderWithdraw2
  },
  {
    url: 'business/withdraw/extract/log',
    router: '/withdrawLog2',
    id: customMenuMap.withdrawLog2
  },
  {
    url: 'business/promoter/manage/product',
    router: '/distributionProduct',
    id: customMenuMap.distributionProduct
  },
  {
    url: 'business/beautify/manage',
    router: '/xiuTuList',
    id: customMenuMap.xiuTuHistory
  },
  {
    url: 'business/beautify/dealer',
    router: '/xiutuSpreadList',
    id: customMenuMap.xiuTuSpreadList
  },
  {
    url: 'business/beautify/statistics',
    router: '/xiutuSpreadStatistic',
    id: customMenuMap.xiuTuSpreadStatistic
  },
  {
    url: 'business/beautify/recharge',
    router: '/xiuTuRecharge',
    id: customMenuMap.xiuTuRecharge
  },
  {
    url: 'business/face/order',
    router: '/CreativeTaskList',
    id: customMenuMap.CreativeTaskList
  },
  {
    url: 'business/face/swap',
    router: '/SingleAi',
    id: customMenuMap.SingleAi,
    newTab: true,
  },
  {
    url: 'business/face/template',
    router: '/CreativeTemplateManage',
    id: customMenuMap.AiTemplate,
    newTab: true,
  },
  {
    url: 'business/face/pay',
    router: '/CreativeRechargeList',
    id: customMenuMap.CreativeRechargeList
  },
  {
    url: 'business/face/category',
    router: '/CreativeClassify',
    id: customMenuMap.CreativeClassify
  },
  {
    url: 'business/face/auth',
    router: '/CreativeUserList',
    id: customMenuMap.CreativeUserList
  },
  {
    url: 'business/face/multiswap',
    router: '/MultiAi',
    id: customMenuMap.MultiAi,
    newTab: true,
  },
  {
    url: 'business/face/dealer/list',
    router: '/CreativeSpreadList',
    id: customMenuMap.CreativeSpreadList
  },
  {
    url: 'business/face/dealer/statistics',
    router: '/CreativeSpreadStatistic',
    id: customMenuMap.CreativeSpreadStatistic
  },
  {
    url: 'business/face/template/type',
    router: '/CreativeClassType',
    id: customMenuMap.CreativeClassType
  },
  {
    url: 'business/face/baidu',
    router: '/BdImage',
    id: customMenuMap.BdImage
  },
];
